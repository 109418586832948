








//@ts-nocheck
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Chart } from '@/models'
import {
  VueApexCharts as ApexChart,
  ApexCharts,
  es
} from '@/plugins/apexcharts'

@Component({
  components: {
    ApexChart
  }
})
export default class MixedChart extends Vue {
  @Prop({ type: Object, required: true }) chart!: Chart
  @Prop({ type: Object, required: true }) result!: any
  @Prop({ type: Object, default: () => ({}) }) queryOptions!: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Boolean, default: false }) loading!: boolean

  get options(): ApexCharts.ApexOptions {
    const options = (this.result && this.result.options) || {}
    if (options.yaxis && Array.isArray(options.yaxis)) {
      for (let yaxis of options.yaxis) {
        if (yaxis && yaxis.labels && yaxis.labels.formatter)
          yaxis.labels.formatter = eval(yaxis.labels.formatter)
      }
    } else if (
      options.yaxis &&
      options.yaxis.labels &&
      options.yaxis.labels.formatter
    )
      options.yaxis.labels.formatter = eval(options.yaxis.labels.formatter)
    if (options.xaxis && options.xaxis.labels && options.xaxis.labels.formatter)
      options.xaxis.labels.formatter = eval(options.xaxis.labels.formatter)
    if (options.dataLabels && options.dataLabels.formatter)
      options.dataLabels.formatter = eval(options.dataLabels.formatter)
    if (options.legend && options.legend.formatter)
      options.legend.formatter = eval(options.legend.formatter)
    if (options.tooltip && options.tooltip.custom)
      options.tooltip.custom = eval(options.tooltip.custom)
    if (
      options.chart &&
      options.chart.events &&
      options.chart.events.dataPointSelection
    )
      options.chart.events.dataPointSelection = eval(
        options.chart.events.dataPointSelection
      )
    if (options.chart && options.chart.events && options.chart.events.click)
      options.chart.events.click = eval(options.chart.events.click)
    if (
      options.plotOptions &&
      options.plotOptions.pie &&
      options.plotOptions.pie.donut &&
      options.plotOptions.pie.donut.labels &&
      options.plotOptions.pie.donut.labels &&
      options.plotOptions.pie.donut.labels.total.formatter
    )
      options.plotOptions.pie.donut.labels.total.formatter = eval(
        options.plotOptions.pie.donut.labels.total.formatter
      )
    return options
  }

  get height() {
    return (this.result && this.result.height) || '400'
  }

  get series() {
    return (this.result && this.result.series) || []
  }

  get type() {
    return (this.result && this.result.type) || []
  }

  get chartType() {
    return (this.result && this.result.chartType) || 'line'
  }
}
